import {
  Container,
  Flex,
  Heading,
  HStack,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'

export function AccessRestrictionErrorScreen() {
  const bg = useColorModeValue('gray.50', 'gray.700')
  const color = useColorModeValue('blue.900', 'blue.50')
  const border = useColorModeValue('gray.200', 'gray.600')
  return (
    <VStack spacing={8} paddingBottom={8} minH={'100vh'} bgColor={bg}>
      <Flex
        paddingTop={6}
        width="full"
        alignItems="center"
        // borderBottom="1px"
        // borderColor={border}
      >
        <Container maxW="container.lg">
          <VStack spacing={4} alignItems={'flex-start'}>
            <HStack justifyContent="space-between" color={color} width={'full'}>
              <HStack>
                <Text
                  fontSize="2xl"
                  fontWeight="extrabold"
                  letterSpacing="wide"
                >
                  ワインスクール井上塾
                </Text>
              </HStack>
            </HStack>
          </VStack>
        </Container>
      </Flex>
      <Container
        maxW={'container.sm'}
        shadow={'md'}
        bg={'white'}
        rounded={'md'}
        padding={4}
        color={color}
      >
        <Heading size={"md"}>ページを表示する権限がないため表示できません</Heading>
        <Text>サイト運営者にご連絡ください。</Text>
      </Container>
    </VStack>
  )
}
