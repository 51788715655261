import { useToast } from '@chakra-ui/react'
import { User } from 'firebase/auth'
import { useAuth } from 'lib/firebase/web/auth/provider'
import { getMessage } from 'lib/toast'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { AccessRestrictionErrorScreen } from 'screens/admin/errors/access-restriction'

interface CreatePageProps<T extends (...args: any) => JSX.Element> {
  component: (props: Parameters<T>[0]) => JSX.Element
  hook?: () => Parameters<T>[0]
  remoteData?: () => Promise<Omit<Parameters<T>[0], 'children'>>
  requireLogin?: boolean
  allowRoles?: string[]
  loginPageUrl?: string
}

export function createPage<T extends (...args: any) => JSX.Element>({
  component: Component,
  hook: useHook = () => {},
  remoteData: remote,
  requireLogin = false,
  allowRoles = [],
  loginPageUrl = '/sign-in',
}: CreatePageProps<T>) {
  const [remoteData, setRemoteData] =
    useState<Omit<Parameters<T>[0], 'children'>>()
  const router = useRouter()
  const data = useHook()
  const toast = useToast()
  const { authenticated, isAdmin, user } = useAuth()
  useEffect(() => {
    if (!remote) {
      return
    }
    remote().then((it) => setRemoteData(it))
  }, [])
  useEffect(() => {
    if (!router.isReady) {
      return
    }
    const { query } = router
    if (query.toast == null) {
      return
    }
    const title = query.toast as string
    toast({ title: getMessage(title) })
  }, [router])

  if (requireLogin && !authenticated) {
    router.push(loginPageUrl)
    return <div></div>
  }

  if (!isAdmin && (allowRoles.includes('admin') || !isActiveUser(user))) {
    return <AccessRestrictionErrorScreen />
  }
  return <Component {...data} {...remoteData} />
}

function isActiveUser(user: User | undefined) {
  if (user?.metadata.creationTime == null) {
    return true
  }
  const creationDate = new Date(user.metadata.creationTime)
  const currentYear = new Date(new Date().getFullYear(), 0, 1)
  return creationDate.getTime() >= currentYear.getTime()
}

export function createAdminPage<T extends (...args: any) => JSX.Element>(
  props: CreatePageProps<T>
) {
  return createPage<T>({
    ...props,
    requireLogin: true,
    allowRoles: ['admin'],
    loginPageUrl: '/admin/sign-in',
  })
}
