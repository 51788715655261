export const getMessage = (path: string) => {
  const pathArr = path.split('.')
  return pathArr.reduce(
    (obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined),
    messages
  )
}

const messages: Record<string, any> = {
  lectures: {
    successfullyDeleted: '正常に講義が削除できました。',
  },
  students: {
    successfullyCreated: '受講者を登録しました',
    successfullyDeleted: '正常に受講者を削除できました。',

  }
}
